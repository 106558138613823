<template>
  <nuxt-link
    :class="['osk-web-to-app-download-app-mobile-top', 'osk-px-md', dense ? 'osk-py-sm' : 'osk-py-md']"
    external
    :to="appLink"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      v-if="withLogo"
      src="~/assets/img/oskelly-logo-small.svg"
      width="38"
      height="38"
      alt="oskelly"
      class="osk-mr-sm"
    >

    <div class="osk-full-width">
      <div
        class="osk-web-to-app-download-app-mobile-top__first-row"
      >
        <div class="osk-web-to-app-download-app-mobile-top__promo-text">
          {{ promoText ?? $t('header.headerPromoBanner.promoText', { sale }) }}
        </div>

        <UikitButton
          v-if="withBtn"
          outline
          :size="withLogo ? 'xs' : 's'"
        >
          {{ $t('header.headerPromoBanner.buttonText') }}
        </UikitButton>
      </div>

      <div
        class="osk-web-to-app-download-app-mobile-top__second-row osk-mt-xs"
      >
        {{ subtitle ?? $t('header.headerPromoBanner.subtitle') }}
      </div>
    </div>

    <slot name="qr-code" />
  </nuxt-link>
</template>

<script lang="ts" setup>
withDefaults(defineProps<{
  withLogo?: boolean
  withBtn?: boolean
  promoText?: string
  subtitle?: string
  appLink?: string
  dense?: boolean
}>(), {
  withBtn: true,
  promoText: undefined,
  subtitle: undefined,
  appLink: undefined,
});
const { sale } = useWebToApp();

</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-web-to-app-download-app-mobile-top {
  @include font-body-all;
  display: flex;
  align-items: flex-start;
  background: $grey-4;
  position: relative;
  border-radius: 2px;
  margin-bottom: 12px;

  &__first-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__second-row {
    @include font-style($font-size-small, $line-height-small, $font-weight-medium);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 224px;

    @include media-query(md-and-up) {
      max-width: 250px;
    }
  }

  &__promo-text {
    @include font-style($font-size-caption, $line-height-caption, $font-weight-bold);
    max-width: 168px;

    @include media-query(md-and-up) {
      max-width: 250px;
    }
  }
}
</style>
